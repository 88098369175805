//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StepLayout from './step-layout.vue';
import Button from '../components/button.vue';
import Radio from '../components/radio.vue';
import stepMixin from '../lib/step-mixin.js';
import okImg from '$resources/images/ok.png';
import { fetchStepsData } from '../lib/api.js';

export default {
  components: {
    StepLayout,
    Button,
    Radio
  },
  mixins: [stepMixin],
  props: {
    leadId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    okImg,
    interval: null
  }),
  async created() {
    this.interval = setInterval(this.getSteps, 10000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    async getSteps() {
      try {
        const response = await fetchStepsData(this.leadId);
        this.$emit('process-step-data', response.data);
      } catch (error) {
        this.$notify.error('Ha ocurrido un error, contacta con el administrador.');
      }
    },
    onContinue() {
      this.$emit('continue', {});
    }
  }
};
